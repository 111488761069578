<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Mercurio.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t("Copla.Mercurio.Configuration") }}</h2>
          <div class="form-group row mt-4">
            <label
              for="session"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.MercurioSubtitles.SessionData.IdSession") }}</label
            >
            <div class="col-xl-1 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="session"
                id="session"
                min="1"
                v-model="mercurio.session"
              />
            </div>
            <label
              for="group"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.MercurioSubtitles.SessionData.IdGroup") }}</label
            >
            <div class="col-xl-1 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="group"
                id="group"
                min="1"
                max="16"
                v-model="mercurio.group"
              />
            </div>
            <label
              for="lines"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.MercurioSubtitles.Format.Lines") }}</label
            >
            <div class="col-xl-1 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="lines"
                id="lines"
                min="1"
                v-model="lineas"
                :readonly="transcribing"
              />
            </div>
            <label
              for="characters"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.MercurioSubtitles.Format.Characters") }}</label
            >
            <div class="col-xl-1 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="caracteres"
                :readonly="transcribing"
              />
            </div>
            <label
              for="fps"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.MercurioSubtitles.SessionData.Server") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <select v-model="mercurio.server" class="custom-select">
                <option selected value="CESyA">{{
                  $t("Copla.MercurioSubtitles.Server.CESyA")
                }}</option>
                <option value="GoogleCloud">{{
                  $t("Copla.MercurioSubtitles.Server.GoogleCloud")
                }}</option>
              </select>
            </div>
            <label
              for="start"
              class="mt-1 col-xl-2 col-form-label text-left text-xl-center"
              >{{ $t("Copla.MercurioSubtitles.Dates.Start") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="datetime-local"
                class="form-control"
                id="start"
                name="start"
                v-model="mercurio.start"
                required
              />
            </div>
            <label
              for="end"
              class="mt-1 col-xl-2 col-form-label text-left text-xl-center"
              >{{ $t("Copla.MercurioSubtitles.Dates.End") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="datetime-local"
                class="form-control"
                id="end"
                name="end"
                v-model="mercurio.end"
                required
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class=" col mr-3">
              <button
                type="button"
                id="comenzar"
                name="comenzar"
                class="btn"
                :class="transcribing ? 'btn-danger' : 'btn-success'"
                v-on:click="postMercurio"
              >
                {{ btnText }}
              </button>
            </div>
          </div>
        </div>
        <table
          class="table table-sm table-striped table-dark mt-5"
          v-if="cafs.length > 0"
        >
          <caption>
            Actualizado:
            {{
              updateTable
            }}
          </caption>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Sesión</th>
              <th>Grupo</th>
              <th>Servidor</th>
              <th>Inicio</th>
              <th>Fin</th>
              <th>SRT</th>
              <th>VTT</th>
              <th>Transcripción</th>
              <th>
                <span class="reload" v-on:click="getCafs()">&#x21bb;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(caf, i) in cafs" :key="i" :id="i">
              <td class="align-middle">{{ datetimeString(caf.date) }}</td>
              <td class="align-middle">{{ caf.mercurio.session }}</td>
              <td class="align-middle">{{ caf.mercurio.group }}</td>
              <td class="align-middle">{{ caf.mercurio.server }}</td>
              <td class="align-middle">
                {{ datetimeString(caf.mercurio.start) }}
              </td>
              <td class="align-middle">
                {{ datetimeString(caf.mercurio.end) }}
              </td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getSRT(caf.id)"
                >
                  Descarga
                </button>
              </td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getVTT(caf.id)"
                >
                  Descarga
                </button>
              </td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getTranscription(caf.id)"
                >
                  Descarga
                </button>
              </td>
              <td
                class="float-right reload align-middle"
                v-on:click="deleteCaf(caf.id)"
              >
                <i class="fas fa-trash"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import AzureAPI from "../js/AzureAPI";
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
import AzureRecognizer from "../js/AzureRecognizer";
import auxiliarFunctions from "../js/AuxiliarFunctions";
import ModalLoading from "../components/ModalLoading.vue";
export default {
  name: "Mercurio",
  components: {
    MainMenu,
    ModalLoading
  },
  data() {
    return {
      ar: new AzureRecognizer({}),
      word: "",
      grammarList: [],
      transcription: "",
      transcribing: false,
      recognizer: undefined,
      btnText: this.$t("Copla.Mercurio.Button-On"),
      languages: this.$t("Copla.Subtitles.Languages"),
      profanities: this.$t("Copla.Subtitles.Profanities"),
      language: "es-ES",
      timedText: {},
      profanity: 2,
      lineas: 2,
      caracteres: 40,
      fps: 30,
      mensajes: "",
      fileDownload: "transcription.txt",
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: "",
      lastOffset: 0,
      initOffset: 0,
      init: 0,
      id: -1,
      source: "File",
      sources: this.$t("Copla.Subtitles.Sources"),
      title: "Prueba",
      azureAPI: new AzureAPI({
        key: process.env.VUE_APP_AZURE_KEY,
        project: process.env.VUE_APP_AZURE_PROJECT,
        location: process.env.VUE_APP_AZURE_LOCATION
      }),
      showModalLoading: false,
      messageModal: "",
      cafs: [],
      updateTable: 0,
      mercurio: {
        session: 103,
        group: 1,
        server: "CESyA",
        start: "2020-10-01T11:00",
        end: "2020-10-01T11:35"
      }
    };
  },
  watch: {
    transcribing: function(val) {
      this.btnText = val
        ? this.$t("Copla.Subtitles.Button-Off")
        : this.$t("Copla.Subtitles.Button-On");
    }
  },
  methods: {
    statusButtons(status) {
      let classes = {
        NotStarted: "btn-primary no-click",
        Running: "btn-warning no-click",
        Failed: "btn-danger no-click",
        Succeeded: "btn-success"
      };
      return classes[status];
    },
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    checkForm: function() {
      let someFieldBlank =
        this.title == "" || this.lineas == "" || this.caracteres == "";
      if (someFieldBlank) {
        alert(this.$t("Copla.Users.Messages.FieldsNotEmpty"));
        return false;
      }
      if (this.source == "File" && this.file == "") {
        alert(this.$t("Copla.Users.Messages.FieldsNotEmpty"));
        return false;
      }
      return true;
    },
    deleteCaf: function(id) {
      if (confirm(this.$t("Copla.Subtitles.Messages.ConfirmDelete"))) {
        this.showModal(true, "Borrando subtítulos");
        let self = this;
        let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
        fsAPI.deleteCAF(id).then(response => {
          this.showModal(false);
          console.log(response);
          self.getCafs();
          self.updateTable = self.datetimeString(Date.now() / 1000);
        });
      }
    },
    getSRT(id) {
      this.showModal(true, "Descargando subtítulos");
      let self = this;
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getSRT(id, 0).then(response => {
        if (response.status == "200") {
          auxiliarFunctions.downloadFile(response.data.data, "subs.srt");
          this.showModal(false);
        } else {
          self.messages = response.data;
          this.showModal(false);
        }
      });
    },
    getVTT(id) {
      this.showModal(true, "Descargando subtítulos");
      let self = this;
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getVTT(id, 0).then(response => {
        if (response.status == "200") {
          auxiliarFunctions.downloadFile(response.data.data, "subs.vtt");
          this.showModal(false);
        } else {
          self.messages = response.data;
          this.showModal(false);
        }
      });
    },
    getTranscription(id) {
      this.showModal(true, "Descargando transcripción");
      let self = this;
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getTranscription(id, 0).then(response => {
        if (response.status == "200") {
          auxiliarFunctions.downloadFile(
            response.data.data,
            "transcription.txt"
          );
          this.showModal(false);
        } else {
          self.messages = response.data;
          this.showModal(false);
        }
      });
    },
    getCafs: function() {
      this.showModal(true, "Cargando subtítulos");
      let self = this;
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.getCAFs().then(response => {
        console.log(response);
        this.showModal(false);
        self.cafs = [];
        response.data.data.forEach(function(value) {
          if (
            value.type == "Mercurio" &&
            value.email == self.$store.state.user
          ) {
            self.cafs.push(value);
          }
        });
        self.updateTable = self.datetimeString(Date.now() / 1000);
      });
    },
    datetimeString: function(ts) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      let date = new Date(ts * 1000);
      let hours = zeroPad(date.getHours(), 2);
      let minutes = zeroPad(date.getMinutes(), 2);
      let seconds = zeroPad(date.getSeconds(), 2);
      let day = zeroPad(date.getDate(), 2);
      let month = zeroPad(date.getMonth() + 1, 2);
      let year = date.getFullYear();
      return (
        day +
        "/" +
        month +
        "/" +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    postMercurio: function() {
      this.showModal(true, "Consultando datos en Mercurio");
      let self = this;
      let params = {
        _name: "Automatic",
        _email: this.$store.state.user,
        _profanity: "Raw",
        _language: "es-ES",
        _lines: this.lineas,
        _characters: this.caracteres,
        _fps: 0,
        _session: this.mercurio.session,
        _group: this.mercurio.group,
        _server: this.mercurio.server,
        _start: this.mercurio.start,
        _end: this.mercurio.end
      };
      let fsAPI = new formatSubtitlesAPI({ base_url: this.base_url });
      fsAPI.postMercurio(params, self.id).then(() => {
        self.getCafs();
      });
    }
  },
  mounted() {
    this.getCafs();
  }
};
</script>

<style scoped>
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
.btn-add-vocabulary {
  width: 100%;
}
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}
</style>
