import axios from "axios";
export default class AzureAPI {
  constructor(data) {
    this.key = data.key;
    this.location = data.location;
    this.project = data.project;
    this.headers = {
      "Ocp-Apim-Subscription-Key": this.key,
      "Content-Type": "application/json"
    };
  }

  async getTranscriptionsList() {
    let self = this;
    let resp = { code: "0", data: "" };
    let url =
      "https://" +
      this.location +
      /*".api.cognitive.microsoft.com/speechtotext/v3.1/projects/" +
      this.project +*/
      ".api.cognitive.microsoft.com/speechtotext/v3.1" +
      "/transcriptions";
    const promise = axios.get(url, { headers: self.headers });
    try {
      const response = await promise;
      resp.code = response.status;
      switch (response.status) {
        case 200:
          resp.data = response.data.values;
          break;
        case 401:
          resp.data = "Authentication is required to access the resource";
          break;
        case 403:
          resp.data = "No permission to access this resource";
          break;
        case 429:
          resp.data =
            "The rate limit has been reached. The timeout in seconds can be found in the Retry-After header";
          break;
      }
      return resp;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getTranscriptionsFiles(id) {
    let self = this;
    let resp = { code: "0", data: "" };
    let url =
      "https://" +
      this.location +
      ".api.cognitive.microsoft.com/speechtotext/v3.1/transcriptions/" +
      id +
      "/files";
    const promise = axios.get(url, { headers: self.headers });
    try {
      const response = await promise;
      resp.code = response.status;
      switch (response.status) {
        case 200:
          var files = response.data.values;
          files.forEach(function(value) {
            if (value.kind == "Transcription") {
              resp.data = value.self;
            }
          });
          break;
        case 400:
          resp.data = "Authentication is required to access the resource";
          break;
        case 401:
          resp.data = "Authentication is required to access the resource";
          break;
        case 403:
          resp.data = "No permission to access this resource";
          break;
        case 404:
          resp.data = "No permission to access this resource";
          break;
        case 429:
          resp.data =
            "The rate limit has been reached. The timeout in seconds can be found in the Retry-After header";
          break;
      }
      return resp;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getTranscriptionsJsonFileUrl(id, fileId) {
    let self = this;
    let resp = { code: "0", data: "" };
    let url =
      "https://" +
      this.location +
      ".api.cognitive.microsoft.com/speechtotext/v3.1/transcriptions/" +
      id +
      "/files/" +
      fileId;
    const promise = axios.get(url, { headers: self.headers });
    try {
      const response = await promise;
      resp.code = response.status;
      switch (response.status) {
        case 200:
          resp.data = response.data.links.contentUrl;
          break;
        case 400:
          resp.data = "Authentication is required to access the resource";
          break;
        case 401:
          resp.data = "Authentication is required to access the resource";
          break;
        case 403:
          resp.data = "No permission to access this resource";
          break;
        case 404:
          resp.data = "No permission to access this resource";
          break;
        case 429:
          resp.data =
            "The rate limit has been reached. The timeout in seconds can be found in the Retry-After header";
          break;
      }
      return resp;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createTranscription(params) {
    let self = this;
    let url =
      "https://" +
      this.location +
      ".api.cognitive.microsoft.com/speechtotext/v3.1/transcriptions";
    const promise = axios.post(url, params, { headers: self.headers });
    try {
      const response = await promise;
      return response;
    } catch (error) {
      return error.response.data;
    }
  }
}
