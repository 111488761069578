import axios from "axios";
export default class AzureRecognizer {
  constructor(options) {
    this.text = "";
    this.complete = "";
    this.live = "";
    this.CAF = { _transcription: "", _phrases: [] };
    if (Object.keys(options).length != 0) {
      var SpeechSDK = window.SpeechSDK;
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        "9d2d6a064406477ca1ef992c4a20872d",
        "westeurope"
      );
      speechConfig.speechRecognitionLanguage = options.language;
      speechConfig.outputFormat = 1;
      speechConfig.setProfanity(options.profanity);
      speechConfig.requestWordLevelTimestamps();
      const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      this.recognizer = new SpeechSDK.SpeechRecognizer(
        speechConfig,
        audioConfig
      );
      const phraseList = SpeechSDK.PhraseListGrammar.fromRecognizer(
        this.recognizer
      );
      options.grammarList.forEach(function(value) {
        phraseList.addPhrase(value);
      });
    }
  }

  startContinuousRecognitionAsync(session,viewer) {
    let self = this;
    this.recognizer.startContinuousRecognitionAsync(
      () => {},
      () => {
        self.killRecognizer();
      }
    );
    this.recognizer.recognizing = function(s, e) {
      if (e.result.text != "") {
        self.live = self.complete + " " + e.result.text;
        if (session != "" && viewer != "") {
          let endPoint = "http://34.77.179.44/mercurio/php/webservice/webserviceVisorExterno.php";
          var bodyFormData = new FormData();
          bodyFormData.append("funcion","escribe");
          bodyFormData.append("sesion",session);
          bodyFormData.append("visor",viewer);
          bodyFormData.append("texto",self.live);
          try {
            const response = axios.post(endPoint, bodyFormData);
            return response;
          } catch (error) {
            return error.response.data;
          }
        }
      }
    };
    this.recognizer.recognized = function(s, e) {
      let result = JSON.parse(e.result.json);
      if (result.DisplayText != "") {
        let phrase = {
          display: result.DisplayText,
          offset: result.Offset,
          duration: result.Duration,
          words: result.NBest[0].Words,
          speaker: 1
        };
        self.CAF._phrases.push(phrase);
      }
      self.complete += e.result.text;
      self.live = self.complete;
      self.text = "";
    };
    this.recognizer.canceled = () => {
      self.killRecognizer();
    };
    this.recognizer.sessionStopped = () => {
      self.killRecognizer();
    };
  }

  stopContinuousRecognitionAsync() {
    let self = this;
    this.recognizer.stopContinuousRecognitionAsync(() => {
      self.killRecognizer();
    });
  }

  killRecognizer() {
    this.CAF._transcription = this.live;
    this.recognizer.dispose(true);
    this.recognizer.close();
    this.recognizer = undefined;
  }
}
